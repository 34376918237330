.c-header {
    background-color: #fff;

    .c-header-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: $spacer--s;
    }

    .logo {
        display: block;
        width: 100%;
        max-width: toRem(200);
    }

    @include breakpoint-up(l) {
        padding-top: $spacer--l;
        padding-bottom: $spacer--l;
    }

    @include breakpoint-down(l) {
        .c-header-content {
            height: 100%;
            min-height: toRem(70);
        }

        .w-container {
            padding: 0;
        }

        .c-call-button {
            height: 100%;
            padding: toRem(23) clamp(toRem(16), 5vw, toRem(32));
            border-left: solid toRem(1) $gray-20;
            border-radius: 0;
        }

        .phone-icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.nav {
    .nav-list {
        display: flex;
        flex-direction: row;
        gap: $spacer--xl;
        list-style: none;
        font-weight: 700;

        a {
            &:hover {
                color: $viridian-dark;
            }
        }
    }

    @include breakpoint-down(l) {
        position: absolute;
        top: toRem(70);
        left: toRem(-1200);
        height: 100%;
        width: 100%;
        z-index: 100;
        background-color: #fff;
        transition: left 0.5s ease-in;

        .nav-list {
            flex-direction: column;
            gap: $spacer;
            padding: $spacer--l;

            li {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                &:not(:first-of-type) {
                    border-top: solid toRem(1) $gray-20;
                    padding-top: $spacer;
                }

                &::after {
                    content: '';
                    display: block;
                    background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%0A%3E%3Cpath d='M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z' fill='currentColor' /%3E%3C/svg%3E");
                    width: toRem(24);
                    height: toRem(24);
                }
            }
        }


        &.is-active {
            left: 0;
            transition: left 0.5s ease-out;
        }
    }
}

.ham-menu {
    height: 100%;

    .hamburger-button {
        background-color: unset;
        border: unset;
        border-right: solid toRem(1) $gray-20;
        padding: toRem(26) $spacer;
        height: 100%;
    }

    .hamburger {
        cursor: pointer;
        display: block;
        height: toRem(18);
        position: relative;
        width: toRem(28);

        &.is-active {
            .line-bar--1 {
                top: 50%;
                transform: rotate(45deg);
                width: 100%;
            }

            .line-bar--2 {
                opacity: 0;
            }

            .line-bar--3 {
                top: 50%;
                width: 100%;
                transform: rotate(-45deg);
            }
        }
    }

    .line-bar {
        display: block;
        width: 100%;
        background-color: $viridian;
        position: absolute;
        left: 0;
        height: toRem(3);
        border-radius: toRem(2);
        margin-top: toRem(-1.5);
    }

    .line-bar--1 {
        top: 0%;
        transform: rotate(0deg);
        width: 50%;
        transition: top 0.2s ease, transform 0.2s ease;

        .c-hamburger.is-active & {
            top: 50%;
            transform: rotate(45deg);
        }
    }

    .line-bar--2 {
        top: 50%;
        opacity: 1;
        transition: opacity 0.1s ease;

        .c-hamburger.is-active & {
            opacity: 0;
            transition: opacity 0.2s ease;
        }
    }

    .line-bar--3 {
        top: 100%;
        transform: rotate(0deg);
        width: 80%;
        transition: top 0.2s ease, transform 0.2s ease;

        .c-hamburger.is-active & {
            top: 50%;
            transform: rotate(-45deg);
        }
    }

    @include breakpoint-up(l) {
        display: none;
    }
}
