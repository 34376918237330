.footer-page {
    padding-top: $spacer--xl;
    padding-bottom: $spacer--xl;
    text-align: center;
    color: $dark;

    .btn--ghost {
        padding: 0;
        background-color: rgba(#000000, 0);
        border-color: rgba(#000000, 0);
        font-size: toRem(16);
        font-weight: 700;
        color: $viridian;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
            color: $lapis-lazuli;
        }
    }

    @include breakpoint-up(l) {
        .footer-content {
            display: grid;
            justify-content: space-between;
            align-items: center;
            grid-template-columns: toRem(700) max-content;
            text-align: left;
        }
    }
}

.footer-page--full {
    padding-top: $spacer--xl;
    padding-bottom: $spacer--xl;

    .footer-list {
        display: flex;
        flex-direction: column;
        gap: $spacer--m;
        list-style: none;
        padding-left: 0;

        a {
            color: $viridian;
            font-weight: 700;

            &:hover {
                color: $viridian-dark;
            }
        }
    }

    .footer-logo-image {
        display: block;
        max-width: toRem(200);
    }

    .footer-left {
        h3 {
            margin-top: $spacer--xl;
        }
    }

    @include breakpoint-up(l) {
        padding-top: 0;
        padding-bottom: 0;
        border-bottom: solid toRem(1) $gray-30;

        .footer-top {
            display: grid;
            grid-template-columns: 1fr 1fr;
            text-align: left;
        }

        .footer-left {
            border-right: solid toRem(1) $gray-30;

            h3 {
                margin-top: $spacer--3xl;
            }
        }

        .footer-left,
        .footer-right {
            padding-top: $spacer--4xl;
            padding-bottom: $spacer--4xl;
        }

        .footer-list {
            flex-direction: row;
            gap: $spacer--l;
        }

        .footer-right {
            padding-left: $spacer--4xl;
        }
    }

    @include breakpoint-down(l) {
        text-align: center;

        .footer-logo-image {
            margin-inline: auto;
            margin-bottom: $spacer--xl;
        }

        .c-button-green {
            margin-inline: auto;
            margin-bottom: $spacer--xl;
        }
    }

    @include breakpoint-down(s) {
        .c-button-green {
            width: 100%;
        }
    }
}