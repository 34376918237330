.form {
    padding: $spacer;
    border: solid toRem(2) $viridian;
    border-radius: $border-radius;
    background-color: white;

    .step {
        height: 100%;
        transition: opacity 0.5s ease-in-out;
        display: none;

        &.is-active {
            display: block;
        }
    }

    .form-group {
        margin-bottom: 15px;

        label {
            display: block;
            margin-bottom: 5px;
        }

        input,
        select {
            width: 100%;
            padding: $spacer--s $spacer--s;
            font-family: $font-family-sans-serif;
            border: toRem(1) solid #ccc;
            border-radius: 4px;
            box-sizing: border-box;
            font-size: toRem(18);
            line-height: toRem(17);
            color: $gray;
        }

        select {
            appearance: none;
            background: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z" fill="currentColor"/></svg>') no-repeat right center;
            background-position-x: calc(100% - 10px);
            background-size: toRem(18) toRem(18);
            height: toRem(48);
        }

        .error-message {
            color: red;
            font-size: 0.8em;
            margin-top: 5px;
        }
    }

    .c-button-green {
        width: 100%;
        justify-content: center;
    }

    .button-group {
        display: grid;
        gap: $spacer;
        grid-template-columns: 1fr 1fr;

        .c-button-green,
        .c-button-outline {
            width: 100%;
        }
    }

    .form-disclaimer {
        font-size: toRem(14);
        line-height: toRem(22);
        text-align: center;
        margin-top: $spacer;

        a {
            color: $viridian;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    @include breakpoint-down(l) {
        text-align: left;
    }

    @include breakpoint-up(l) {
        padding: $spacer--xl;

        .form-group-two-column {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: $spacer;
        }
    }
}

.c-form__group--onnohoney {
    display: none !important;
}