.find-attorney {
    .benefits-of-filing,
    .get-started {
        padding-top: $spacer--3xl;
        padding-bottom: $spacer--3xl;
    }

    .meso-lawyer-can-help {
        padding-top: $spacer--3xl;
        padding-bottom: $spacer--3xl;
    }

    .get-started {
        text-align: center;

        .link {
            color: $cream;
            text-decoration: underline;
            text-underline-offset: toRem(2);
        }

        .action-block {
            h3 {
                color: #fff;
                margin-top: $spacer--l;
            }
        }
    }

    .cta-factbox {
        margin-top: $spacer--l;
    }

    @include breakpoint-up(l) {
        .aside-content,
        .hero-image {
            height: 100%;
        }

        .subheading-p {
            margin-bottom: 0;
        }

        .hero-image {
            max-height: toRem(650);
            margin-bottom: 0;
        }

        .media-block--media-small {
            .media-block__image {
                max-height: toRem(450);
                height: 100%;
                object-fit: cover;
            }
        }

        .benefits-of-filing {
            text-align: center;
            padding-top: $spacer--5xl;
            padding-bottom: $spacer--5xl;

            h1 {
                margin-bottom: $spacer--xl;
            }
        }

        .meso-lawyer-can-help {
            padding-top: $spacer--5xl;
            padding-bottom: $spacer--5xl;
        }

        .cta-factbox {
            margin-top: $spacer--xl;
        }

        .action-block--card {
            text-align: center;
        }

        .get-started {
            h2 {
                margin-bottom: $spacer--3xl;
            }
        }
    }

    @include breakpoint-down(l) {
        .hero-image {
            object-position: 0 50%;
        }

        .media-block--media-small {
            .media-block__image {
                display: block;
                max-height: toRem(336);
                width: 100%;
                object-fit: cover;
            }
        }

        .media-block__media {
            margin-bottom: $spacer--l;
        }
    }

    @include breakpoint-between(s,l) {
        .media-block--media-small {
            .media-block__image {
                border-radius: toRem(8);
            }
        }
    }

    @include breakpoint-down(s) {
        .meso-lawyer-can-help {
            padding-top: 0;
        }

        .media-block--media-small {
            .media-block__image {
                @include make-full-width;
                max-height: toRem(228);
            }
        }

        .get-started {
            .action-block {
                h3 {
                    margin-bottom: $spacer--xs;
                }
            }
        }
    }
}