
@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}

@function toRem($pxValue) {
    @return calc(#{$pxValue} / 16 * 1rem);
}

@mixin make-full-width() {
    position: relative;
    right: 50%;
    left: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    max-width: 100vw;
    width: 100vw;
}


.w-container {
    max-width: calc(toRem(1384) + toRem(128));
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacer--l;
    padding-right: $spacer--l;

    @include breakpoint-up(l) {
        padding-left: $spacer--4xl;
        padding-right: $spacer--4xl;
    }
}

.w-container-sm {
    max-width: calc(toRem(1240) + toRem(128));
    margin-left: auto;
    margin-right: auto;
    padding-left: $spacer--l;
    padding-right: $spacer--l;

    @include breakpoint-up(l) {
        padding-left: $spacer--4xl;
        padding-right: $spacer--4xl;
    }
}

.hide-mobile {
    display: none;

    @include breakpoint-up(s) {
        display: block;
    }
}

.hide-desktop {
    display: block;

    @include breakpoint-up(s) {
        display: none;
    }
}

.hidden {
    display: none;
}

.u-sr-only {
    height: toRem(1);
    width: toRem(1);
    overflow: hidden;
    padding: 0;
    position: absolute;
    clip: rect(0, 0, 0, 0);
    background-color: #fff;
    border: 0;
    color: #000;
    white-space: nowrap;
}

.cream-bg {
    background-color: $cream;
}

.light-cream-bg {
    background-color: $light-cream;
}

.viridian-bg {
    background-color: $viridian;
    color: #fff;

    h2 {
        color: #fff;
    }
}

.viridian-dark-bg {
    background-color: $viridian-dark;
    color: #fff;

    h2 {
        color: #fff;
    }
}