$breakpoints: (
    s: 560px,
    l: 1068px,
    xl: 1440px
);

@mixin breakpoint-up($name) {
    $breakpoint: map-get($breakpoints, $name);
    @media only screen and (min-width: $breakpoint) {
        @content;
    }
}

@mixin breakpoint-down($name) {
    $breakpoint: map-get($breakpoints, $name);
    @media only screen and (max-width: ($breakpoint - 1)) {
        @content;
    }
}

@mixin breakpoint-between($lower, $upper) {
    $lower_breakpoint: map-get($breakpoints, $lower);
    $upper_breakpoint: map-get($breakpoints, $upper);
    @media only screen and (min-width: $lower_breakpoint) and (max-width: ($upper_breakpoint - 1)) {
        @content;
    }
}
