.privacy-policy,
.disclaimer,
.data-request {
    p {
        margin-bottom: 1rem;
    }

    .c-hero {
        text-align: center;

        .c-hero-content {
            grid-template-columns: 1fr;
        }
    }

    .content {
        padding-top: $spacer--3xl;
        padding-bottom: $spacer--3xl;

        .w-container-sm {
            max-width: calc(toRem(800) + toRem(128));
        }
    }

    .form {
        border-radius: toRem(8);
        border: toRem(1) solid $gray-20;
        box-shadow: 0 toRem(4) toRem(20) 0 rgba(0, 0, 0, 0.25);
        padding: 0;
        margin-bottom: $spacer--6xl;
        margin-top: $spacer--xl;

        .form-title {
            font-family: $font-family-serif;
            font-size: toRem(27);
            line-height: normal;
            font-weight: 400;
            color: white;
            background-color: $viridian-dark;
            padding: toRem(30) $spacer--xl;
            border-radius: toRem(8) toRem(8) 0 0;
        }

        fieldset {
            padding: $spacer--2xl $spacer--xl;
        }

        .form-inline {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: $spacer--m;
            width: 100%;
    
            > * {
                flex: 1 1 300px;
            }
        }

        .form-group {
            select {
                width: 100%;
                margin-bottom: 0;
                height: toRem(54);
            }
        }

        .radio-group {
            display: flex;
            align-items: center;
            gap: toRem(32);
    
            .radio-item {
                display: flex;
                align-items: center;
                gap: toRem(10);
        
                input[type="radio"] {
                appearance: none;
                -webkit-appearance: none;
                width: toRem(15);
                height: toRem(15);
                border-radius: toRem(5);
                border: toRem(2) solid $gray-20;
                background-color: white;
                position: relative;
                cursor: pointer;
                margin-left: 0;
        
                &:checked {
                    background-color: $viridian-dark;
                    border-color: $viridian-dark;
        
                    &::after {
                        content: '✓';
                        position: absolute;
                        top: 50%;
                        left: 45%;
                        transform: translate(-50%, -50%);
                        font-size: toRem(16);
                        font-weight: 700;
                        color: white;
                    }
                }
        
                &:focus {
                    outline: toRem(2) solid $viridian-dark;
                } 
        
                &:hover {
                    border-color: $viridian-dark;
                }
            }
        }

            label {
                margin-bottom: 0;
                line-height: normal;
            }
        }

        .row-left {
            display: flex;
            justify-content: flex-end;
            margin-top: toRem(10);
        }

        .row-between {
            display: flex;
            justify-content: space-between;
            margin-top: toRem(10);
        
            .c-button-green {
                width: unset;
            }
        }

        textarea {
            width: 100%;
        }

        small {
            font-size: toRem(14);
            line-height: 1.2;
            color: $gray-50;
            font-style: italic;
            display: block;
            margin: toRem(10) 0;
        }
    }

    @include breakpoint-up(l) {
        .row-left,
        .row-between {
            button {
            width: unset;
            }
        }
    }
}