.c-hero {
    padding-bottom: $spacer--3xl;

    .c-hero-content {
        display: grid;
        gap: $spacer--xl;
        align-items: center;
    }

    .subheading-p {
        color: $gray;
        font-size: toRem(20);
        line-height: toRem(32);
        margin-bottom: 0;
    }

    @include breakpoint-up(l) {
        padding-top: $spacer--5xl;
        padding-bottom: $spacer--5xl;

        h1 {
            margin-bottom: $spacer--xl;
        }

        .c-hero-content {
            grid-template-columns: 1fr 1fr;
            gap: $spacer--4xl;
        }

        .subheading-p {
            font-size: toRem(22);
        }

        .hero-image {
            border-radius: $border-radius;
        }
    }

    @include breakpoint-down(l) {
        .main-content {
            order: 2;
        }

        .aside-content {
            order: 1;
        }

        .hero-image {
            @include make-full-width;
            display: block;
            object-fit: cover;
            height: clamp(toRem(200), 40vw, toRem(420));    
            object-position: 0 13%;
        }
    }
}

.c-hero-lp {
    .c-hero-lp-content {
        display: grid;
        gap: $spacer;
        padding-bottom: $spacer--xl;
    }

    .hero-card {
        border: solid toRem(1) $dark;
        border-radius: $border-radius;
        padding: $spacer;
        display: flex;
        gap: $spacer;
        flex-direction: row;
        font-size: 14px;
        line-height: 20px;

        img {
            width: fit-content;
        }
    }

    .hero-cards {
        display: grid;
        gap: $spacer;
        grid-template-columns: 1fr;
    }

    .hero-image {
        object-fit: cover;
    }

    .subheading-p {
        color: $gray;
    }

    @include breakpoint-up(l) {
        padding-top: $spacer--2xl;
        padding-bottom: toRem(80);

        .c-hero-lp-content {
            grid-template-columns: 1fr 1fr;
            gap: $spacer--4xl;
            align-items: flex-end;
        }

        .aside-content {
            order: 1;
        }

        .main-content {
            order: 2;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }

        .form {
            margin-top: $spacer--xl;
        }

        .hero-cards-mobile {
            display: none;
        }

        .hero-image {
            height: toRem(480);
            width: 100%;
            margin-bottom: $spacer;
            border-radius: $border-radius;
        }
    }

    @include breakpoint-down(l) {
        .hero-image {
            width: 100%;
            height: clamp(toRem(200), 40vw, toRem(420));    
            object-position: 0 13%;
        }

        .hero-cards {
            padding-top: $spacer--l;

            &-desktop {
                display: none;
            }
        }
    }

    @include breakpoint-up(s) {
        .hero-cards {
            grid-template-columns: repeat(3, 1fr);
        }

        .hero-card {
            flex-direction: column;
            text-align: center;
            align-items: center;
        }
    }

    @include breakpoint-down(l) {
        .hero-image {
            @include make-full-width;
        }
    }

    @include breakpoint-between(s, l) {
        .main-content {
            text-align: center;
        }
    }

    .main-content {
        order: 1;
    }
}

$aside-max-width: toRem(380);

.c-hero-backpage {
    padding-top: $spacer--3xl;
    padding-bottom: $spacer--3xl;
    background-color: $viridian-dark;
    color: #fff;

    .main-content {
        width: 100%;

        h1 {
            color: #fff;
        }

        p {
            font-size: toRem(22);
            line-height: toRem(32);
        }
    }

    .aside-content {
        display: none;
    }

    .hero-image {
        display: block;
        border-radius: toRem(8);
        margin-bottom: $spacer--xl;
        overflow: hidden;
    }

    @include breakpoint-up(l) {
        padding-top: $spacer--5xl;
        padding-bottom: $spacer--5xl;

        .c-hero-content {
            display: flex;
            position: relative;
            gap: $spacer--4xl;
            margin-left: -$spacer--4xl;
            margin-right: -$spacer--4xl;
        }

        .main-content {
            max-width: toRem(690);
            padding-left: $spacer--4xl;
            padding-right: $spacer;
        }

        .aside-content {
            max-width: $aside-max-width;
            display: block;
            background-color: #fff;
            border-top-left-radius: toRem(8);
            border-top-right-radius: toRem(8);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 50;
            height: 100%;
            padding-top: $spacer--l;
            padding-right: $spacer--l;
            padding-bottom: $spacer--l;
            padding-left: $spacer--l;
            min-height: toRem(390);
        }
    }

    @include breakpoint-down(l) {
        .main-content {
            max-width: toRem(740);
            margin-inline: auto;
        }
    }

    @media (width >= toRem(1190)) {
        .main-content {
            max-width: toRem(792);
        }
    }
}