.cta-factbox {
    background-color: #fff;
    border: solid toRem(1) $viridian;
    border-radius: toRem(8);
    padding: $spacer--l;

    ul {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: $spacer;
    }

    .list-title {
        font-weight: 700;
        margin-bottom: $spacer--l;
    }

    @include breakpoint-up(l) {
        display: grid;
        grid-template-columns: 1fr max-content;
        align-items: center;
        gap: $spacer--l;
        padding: $spacer--xl;

        .c-button-green {
            height: fit-content;
        }
    }

    @include breakpoint-down(l) {
        .c-button-green {
            width: 100%;
            margin-top: $spacer--xl;
        }
    }
}

.cta-banner {
    padding-top: $spacer--xl;
    padding-bottom: $spacer--xl;

    .cta-content {
        display: grid;
        gap: $spacer--xl;
    }

    .c-button-green-light {
        padding-right: $spacer--l;
        padding-left: $spacer--l;
    }

    h3 {
        color: #fff;
        margin-bottom: 0;
    }

    @include breakpoint-up(l) {
        .cta-content {
            grid-template-columns: 1fr max-content;
        }
    }
}

.cta-home {
    .cta-content {
        p {
            margin-bottom: $spacer;
        }
    }

    img {
        display: block;
    }

    .pill {
        display: inline-block;
        padding: $spacer--2xs $spacer;
        border-radius: 10rem;
        color: #fff;
        border: #fff solid toRem(1);
        font-size: toRem(14);
    }

    .c-button-green-light {
        background-color: #4A9D6B;
        border-color: #4A9D6B;
        color: #fff;

        svg path {
            fill: #fff;
        }

        &:hover {
            background-color: $lapis-lazuli;
            border-color: $lapis-lazuli;         
        }
    }

    @include breakpoint-up(l) {
        display: flex;	
        align-items: center;
        min-height: toRem(420);
        color: #fff;

        .cta-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
        }

        .cta-content {
            p {
                margin-bottom: $spacer--xl;
            }
        }

        .pill {
            margin-bottom: $spacer--xl;
            font-size: toRem(16);
        }

        h2 {
            color: #fff;
        }

        img {
            position: absolute;
            z-index: -1;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    @include breakpoint-down(l) {
        img {
            @include make-full-width;
        }

        h2 {
            margin-bottom: $spacer--xs;
        }

        .cta-content {
            padding-top: $spacer--xl;
        }

        .pill {
            margin-bottom: $spacer;
        }
    }
}

.cta-footer {
    padding-top: $spacer--xl;

    h2 {
        margin-bottom: $spacer--xl;
    }

    .cta-content {
        order: 1;

        p {
            margin-bottom: $spacer;
        }
    }

    .pill {
        display: inline-block;
        padding: $spacer--2xs $spacer;
        border-radius: 10rem;
        color: #fff;
        border: #fff solid toRem(1);
        font-size: toRem(14);
        margin-bottom: $spacer--xl;
    }

    .cta-container {
        display: grid;
        gap: $spacer--xl;
    }

    .cta-image {
        display: block;
        order: 2;
    }

    @include breakpoint-up(l) {
        padding-top: $spacer--2xl;

        .cta-container {
            grid-template-columns: 1fr minmax(0, max-content);
            align-items: center;
            justify-content: center;
            height: 100%;
            gap: $spacer--4xl;
        }

        .cta-content {
            p {
                margin-bottom: $spacer--xl;
            }
        }
    }

    @include breakpoint-down(l) {
        .cta-content {
            padding-top: $spacer--xl;
        }

        .cta-image {
            margin-inline: auto;
        }
    }

    @include breakpoint-down(s) {
        .c-button-green-light {
            width: 100%;
        }
    }
}

.cta-inline {
    background-color: $viridian-dark;
    border-radius: toRem(8);
    margin-top: $spacer--3xl;
    margin-bottom: $spacer--3xl;
    overflow: hidden;
    color: #fff;

    .cta-container {
        display: flex;
        flex-direction: column;
    }

    .cta-content {
        padding: $spacer--l $spacer--xl;
    }

    .cta-overline {
        display: none;
    }

    .cta-title {
        font-size: toRem(32);
        line-height: toRem(36);
        font-family: $font-family-serif;
        font-weight: 500;
        margin-bottom: $spacer;
    }

    .cta-image {
        display: block;
        align-self: center;
    }

    .cta-button {
        margin-top: $spacer--xl;
    }

    @include breakpoint-up(l) {
        .cta-container {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
        }

        .cta-content {
            grid-column: 1 / span 7;
            grid-row: 1;
        }

        .cta-image {
            align-self: self-end;
            grid-column: 7 / span 6;
            grid-row: 1;
            justify-self: end;
            margin-right: -$spacer--xl;
        }
    }

    @include breakpoint-down(l) {
        .cta-overline {
            display: block;
            font-size: toRem(14);
            line-height: toRem(20);
            font-weight: 400;
            border: solid toRem(1) #fff;
            width: fit-content;
            border-radius: $spacer--5xl;
            padding: $spacer--xs $spacer;
            margin-bottom: $spacer--2xl;
        }
    }

    @include breakpoint-down(s) {
        @include make-full-width;

        .cta-image {
            padding-right: $spacer--s;
            padding-left: $spacer--s;
        }

        .cta-button {
            width: 100%;
        }
    }
}

.cta-compact {
    .cta-intro {
        font-size: toRem(24);
        line-height: toRem(32);
        font-weight: 400;
        margin-bottom: $spacer--l;
    }

    .cta-container {
        display: grid;
        border: solid toRem(1) $viridian;
        border-radius: toRem(8);
        gap: $spacer;
        padding: $spacer--s $spacer;
        background-color: rgba($viridian, 0.1);
    }

    @include breakpoint-up(l) {
        .cta-container {
            grid-template-columns: 1fr minmax(250px, 1fr);
            gap: $spacer--2xl;
        }

        .c-button-green {
            gap: $spacer;
        }
    }
}

.cta-full-width {
    position: relative;
    overflow: hidden;
    padding-bottom: $spacer--xl;

    @include breakpoint-up(l) {
        padding-top: $spacer--5xl;
        padding-bottom: $spacer--5xl;
        background: linear-gradient(to right, rgba(36, 36, 41, 0.8) 10%, rgba(0, 0, 0, 0));
    }
}