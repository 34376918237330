@mixin heading-styles($font-size, $line-height, $font-size-desktop, $line-height-desktop, $margin, $font-weight, $font-family) {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    margin-bottom: $margin;
    color: $dark;

    @include breakpoint-up(s) {
        font-size: $font-size-desktop;
        line-height: $line-height-desktop;
    }
}

h1 {
    @include heading-styles(40px, 44px, 58px, 62px, $spacer--l, 500, $font-family-serif);
}

h2 {
    @include heading-styles(32px, 36px, 42px, 54px, $spacer--l, 500, $font-family-serif);
}

h3 {
    @include heading-styles(21px, 28px, 24px, 33px, 1rem, 700, $font-family-sans-serif);
}

h5 {
    @include heading-styles(18px, 25px, 24px, 33px, 1rem, 700, $font-family-serif);
}


.subheading {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: $spacer--l;
    color: $coyote;

    @include breakpoint-up(s) {
        font-size: 24px;
        line-height: 33px;
    }
}